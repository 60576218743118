@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --background-color: #000000;
  --card-background-color: #1b1b1b;
  --card-outline-color: #1b1b1b;
  --heading-font-color: #ffffff;
  --heading-font-weight: 200;
  --hover-color: #ffffff;
  --paragraph-font-color: #a4a4a4;
  --rhythm-base: 1em;
  --rhythm-mult: 1.25;
  --text-color: #ffffff;
  --title-font: "IBM Plex Sans", sans-serif;
  --title-font-weight: 200;
  --text-transform: capitalize;
}

/* 
[data-theme="dark"] {
  --background-color: #111111;
  --card-background-color: #1a1a1a;
  --card-outline-color: #515151;
  --heading-font-color: #ffffff;
  --hover-color: #ffffff;
  --paragraph-font-color: #a9a9a9;
  --text-color: #ffffff;
  --title-font: "IBM Plex Sans", sans-serif;
  --title-font-weight: 200;
  --text-transform: capitalize;
}
*/

body,
html {
  color: var(--text-color);
  background: var(--background-color);
  overflow-x: hidden;
  /* padding-bottom: 1em;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 1em;
  padding-bottom: 1em; */

  transition: color 0.5s, background 0.5s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navigation-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 0;
  z-index: 10000;
  top: 30;
}

.navigation-buttons .back-button,
.navigation-buttons .next-button {
  cursor: pointer;
  background: none;
  font-size: 3em;
  border: none;
  color: #ffffff;
  padding: 0;
  margin: 0;

  /* Add other styling here */
}
.navigation-buttons .next-button.disabled {
  opacity: 0.25; /* Lower opacity when there is no next page */
  cursor: default;
}
.navigation-buttons .back-button.disabled {
  opacity: 0.25; /* Lower opacity when there is no next page */
  cursor: default;
}

.no-scroll {
  overflow: hidden;
}
.pageLayout-wrapper {
  font-size: 16px;
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.8s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInAnimation {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
  /* border: 1px solid #ff0000; */
}

/* .app-wrapper {
  padding-left: 4em;
  padding-right: 4em;
  padding-top: 4em;
  padding-bottom: 4em;
} */

/* .grid-container {
  display: grid;
  background: none;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.grid-container > div {
  background: none;
} */

.paragraph-component,
.card-component,
.title-component,
.photo-component {
  font-family: "IBM Plex Sans", sans-serif;
}

.video-wrapper {
  /* overflow: hidden; */
  /* border: 1px solid #ff0000; */
  margin-bottom: 5em;
  /* background: var(--card-background-color); */
  /* border: 2px solid var(--card-outline-color); */
}
.vid-height {
  /* height: 30em; */
}

.card-icons-container {
  position: absolute;
  height: 2em;
  width: 100%;
  top: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.5em;
  gap: 0.5em;
}
.card-content {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 0em;
}

/* Optional: If you want the action buttons or any element at the bottom of the card to align at the bottom */
.card-action {
  margin-top: auto;
}
.card-icon {
  flex: 0 0; /* Prevents the icon from growing or shrinking */

  width: 0.65em; /* Allow the width to be determined by the image's aspect ratio */
  height: 0.65em; /* Adjusted size for better fit */
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(145, 145, 145, 0.5); /* Adds a white outer glow */

  transition: width 0.5s, height 0.5s;
}
.card-image {
  position: relative;
  overflow: hidden;
  display: block;
  /* border: 2px solid var(--card-outline-color); */
  /* mix-blend-mode: luminosity; */
  /* Ensure this is higher than the z-index of blocking elements */
}
.card-image img {
  width: 100%; /* Ensure the image covers the full width of its container */
  height: auto; /* Adjust height automatically to maintain aspect ratio */
  object-fit: cover; /* Cover the container without losing the aspect ratio. You can also try other values like 'contain' */
  object-position: center; /* Center the image within the container */

  /* background-color: black; */
}

.card-image-fit img {
  object-fit: fill;
  object-position: center center;
}

.align-top-left img {
  object-fit: cover;
  object-position: left top;
}

.card-image-wrapper {
  width: 100%; /* Ensure full width */
  /* Adjust based on your design, could be a specific height */
  /* background-color: #acacac; */
}

.card-image:hover {
  mix-blend-mode: normal;
  /* border-style: outset; */
  /* background-color: black; */
}
.card-image-height {
}
.card-image-height-short {
  height: 7em;
}

.card-image-multi {
  display: flex; /* Align images horizontally */
  width: 100%; /* Ensure the container spans the full width */
  overflow-x: auto; /* Allows horizontal scrolling if images exceed the container width */
}

.card-image-multi-item {
  flex: 1; /* Allows each image to grow and fill the container equally */
  object-fit: cover; /* Adjusts the size of the image to cover the container while maintaining aspect ratio */
  min-width: 0; /* Prevents flex items from growing beyond their content size */
  margin-right: 1px;
}

.card-background {
  /* position: relative; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card-wrapper {
  margin-bottom: 4em;
  /* background: var(--card-background-color); */
  border: 1px solid var(--card-outline-color);

  /* border: 1px solid #ff0000; */
  /* padding: 1em; */
  /* border: 1px solid #1a1a1a; */
}

.mm-img {
  width: 50%;
  height: 50%;
}

.row {
  padding-left: 0.5em;
  padding-top: 0.5em;
}

.horizontal-line {
  border-top: 1px solid #cccccc;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h1 {
  margin-top: 1em;
  line-height: calc(1.95em * var(--rhythm-mult));
  font-size: calc(1.25em * var(--rhythm-mult) * 1.25);
  font-family: var(--heading-font);
  font-weight: var(--heading-font-weight) !important;
  text-transform: var(--text-transform);
  color: var(--heading-font-color);
  /* color: var(--paragraph-font-color); */
}
h2 {
  margin-top: 1em;
  line-height: calc(1.56em * var(--rhythm-mult));
  font-size: calc(1.56em * var(--rhythm-mult));
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--heading-font);
  text-transform: var(--text-transform);
  font-weight: var(--heading-font-weight) !important;
  color: var(--heading-font-color);
}

h3 {
  margin-top: 1em;
  line-height: calc(1.25em * var(--rhythm-mult));
  font-size: calc(1.25em * var(--rhythm-mult));
  font-family: var(--heading-font);
  font-weight: var(--heading-font-weight) !important;
  color: var(--heading-font-color);
}

h4 {
  margin-top: 1em;
  font-size: calc(1em * var(--rhythm-mult));
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--heading-font);
  font-style: bold;
  font-weight: var(--heading-font-weight) !important;
  color: var(--heading-font-color);
}

h5 {
  margin-top: 1em;
  font-size: calc(0.5em * var(--rhythm-mult) * 1.25);
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--heading-font);
  font-style: bold;
  font-weight: var(--heading-font-weight) !important;
  color: var(--heading-font-color);
}

p {
  margin-top: -0.25em;
  font-size: calc(1em * var(--rhythm-mult));
  font-family: var(--paragraph-font);
  font-weight: var(--paragraph-font-weight);
  font-style: normal;
  color: var(--paragraph-font-color);
  text-align: left;
}
.column {
  /* margin: 0.5em; */
}
.paragraph {
}
.align-bottom {
  vertical-align: bottom;
}

.shot {
  height: 6em;
}

.short {
  height: 10em;
}
.tall {
  height: 10em;
}
.grande {
  height: 16em;
}
.venti {
  height: 24em;
}
.trenta {
  height: 36em;
}
.large-heading {
  font-size: calc(1.95em * var(--rhythm-mult) * 1.5);
  line-height: 1em;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: var(--title-font-weight);
  text-transform: --text-transform;
  transition: font-size 0.5s;
  color: var(--heading-font-color);
}

.large-heading-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* white-space: nowrap; */
}

.hamburger {
  background: none;
  border: none;
  font-size: 3em;
  cursor: pointer;
  color: var(--heading-font-color); /* Make the hamburger icon white */
  position: fixed; /* Fix position to the viewport */
  right: 0; /* Align with the right side of the viewport */
  top: 0;

  z-index: 100000; /* Ensure it's above other content */
  /* margin-left: 1em; */
  /* width: 2em; */
}
.menu {
  position: fixed;
  left: 0;
  bottom: -125%; /* Start off-screen */
  width: 100%;
  height: 100vh;
  background-color: #ff0066;
  z-index: 1000;
  transition: bottom 1s ease; /* Smooth transition for sliding in */
  font-weight: 300;

  /* border: 1px solid #00ff00; */
}
.menu.active {
  bottom: 0; /* Slide in */
}

nav ul {
  font-size: 6em;
  list-style-type: none;
  padding: 0;
  margin: 0; /* Reset margin to align with the top of the page */
}

nav ul li {
  margin: 0px 3px;
}

nav {
  /* width: 50vw; */
  /* height: 100vh; */
  /* background-color: rgba(0, 0, 0, 0.9); */
  /* border: 1px solid #00ff00; */

  z-index: 999; /* Below the hamburger icon */
}
.app-wrapper {
  padding: 5em; /* Example padding */
}
.nav-wrapper {
  width: 50vw;
  height: 100vh;
  left: 50vw;
  /* margin-left: 3em;
  margin-top: 3em; */
  position: absolute; /* Add or change to absolute positioning */
  /* border: 10px solid #ffffff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-gradient {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 0) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  z-index: 10;
}
.bottom-gradient {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  z-index: 10;
}
.close-button {
  position: fixed;
  top: 60;
  left: 4;
  background: none;
  color: white;
  border: none;
  font-size: 4rem;
  line-height: 1;
  z-index: 100000;
}

@media (min-width: 300px) {
  /* .large-heading {
    font-size: calc(1.95em * var(--rhythm-mult) * 1);
  } */
  body,
  html {
    padding-left: 0em;
    padding-right: 0em;
    font-size: 10px;
  }
  nav ul {
    font-size: 2em;
  }

  .app-wrapper {
    padding: 2em; /* Example padding */
    font-size: 1em;
  }
  /* .app-wrapper {
    padding: 2em;
    font-size: 14px;
  } */
  .nav-wrapper {
    left: 30vw;
  }
  .motormouth-logo img {
    transform: scale(0.25);
    transform-origin: center; /* Adjust as needed */
  }

  p {
    font-size: calc(0.75em * var(--rhythm-mult));
  }
  h1 {
    font-size: calc(1.25em * var(--rhythm-mult) * 1);
  }
  h2 {
    font-size: calc(1em * var(--rhythm-mult) * 1);
  }
  h3 {
    font-size: calc(1em * var(--rhythm-mult) * 1);
    font-weight: var(--heading-font-weight) !important;
    line-height: 1.5em !important;
  }
  h4 {
    font-size: calc(0.75em * var(--rhythm-mult) * 1);
  }
  h5 {
    font-size: calc(0.5em * var(--rhythm-mult) * 1);
  }
  .navigation-buttons {
    top: 20;
    right: 10;
  }
  .close-button {
    top: 18;
    left: 8;
  }
}

@media (min-width: 576px) {
  /* .large-heading {
    font-size: calc(1.95em * var(--rhythm-mult) * 1);
  } */

  nav ul {
    font-size: 2.5em;
  }
  .nav-wrapper {
    left: 30vw;
  }
}

@media (min-width: 768px) {
  .large-heading {
    font-size: calc(1.95em * var(--rhythm-mult) * 1.5);
  }

  nav ul {
    font-size: 3em;
  }
  .nav-wrapper {
    left: 30vw;
  }

  .motormouth-logo img {
    transform: scale(1);
    transform-origin: center; /* Adjust as needed */
  }

  .app-wrapper {
    padding: 3em;
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .large-heading {
    font-size: calc(1.95em * var(--rhythm-mult) * 2);
    transition: font-size 0.5s;
  }

  nav ul {
    font-size: 3em;
  }
  .nav-wrapper {
    left: 50vw;
  }

  .app-wrapper {
    padding: 8em; /* Example padding */
  }
}

@media (min-width: 1200px) {
  nav ul {
    font-size: 4em;
  }
  .close-button {
    top: 80;
    left: 18;
  }
  .navigation-buttons {
    top: 90;
    right: 30;
  }
}

@media (min-width: 1400px) {
  nav ul {
    font-size: 5em;
  }
  .large-heading {
    font-size: calc(1.95em * var(--rhythm-mult) * 3);
  }
  .tech-icon {
    width: 3em;
    height: 3em;
    /* transition: width 0.5s, height 0.5s; */
  }
  .icon-row {
    justify-content: flex-start;
  }

  .card-image-height-short {
    height: 12em;
  }
}

div {
  /* border: 1px solid #ff0000; */
}

a {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1em;
  color: #000000;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  color: var(--hover-color);
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

/* div {
  border: 1px solid red;
} */
