@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

label {
  display: none;
  background: #ff6b20;
  color: white;
  padding: 6px 8px;
  border-radius: 7px;
  white-space: nowrap;
}

.content-container,
.content {
  width: 100%;
}
.page {
  width: 100vw;
}

.page-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3em;
  height: 100vh;
  width: 100%;
}

.nav-arrow {
  color: #ff0066;
  font-size: 3em;
  line-height: 2.5em;
  font-family: "IBM Plex Sans", sans-serif;
  margin-right: 10px;
}
.clickable-title:hover {
  color: #ff0066; /* or any other style you want for clickable titles */
  cursor: pointer;
}

.title-wrapper {
  color: rgb(255, 255, 255);
  font-size: 7em;
  line-height: 1em;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 100;
  margin-bottom: 0.1em;
  /* padding-right: 2em;
  padding-left: 2em; */
}

.sub-title-wrapper {
  color: rgba(255, 255, 255, 0.75);
  font-size: 2em;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 200;
  line-height: 1.25em;
  text-wrap: wrap;
  /* padding-right: 2em;
  padding-left: 2em; */
}

.section-content {
  right: 0;
  width: 50vw;
}

.clickable-title {
  cursor: pointer;
}

.sphereGrid {
}

@media (min-width: 300px) {
  .section-content {
    width: 100vw;
    padding: 0 7em; /* Add padding on both sides */
  }
  .title-wrapper {
    font-size: 4em;
  }
  .sub-title-wrapper {
    font-size: 1.5em;
  }
  .nav-arrow {
    font-size: 2em;
  }
}
@media (min-width: 576px) {
  .page-section {
    justify-content: flex-start;
  }

  .section-content {
    width: 100vw;
    padding: 0 7em; /* Add padding on both sides */
  }
}

@media (min-width: 767px) {
  .page-section {
    justify-content: flex-start;
  }
  .title-wrapper {
    font-size: 5em;
  }
  .sub-title-wrapper {
    font-size: 2em;
  }
  .nav-arrow {
    font-size: 3em;
  }
}
@media (min-width: 992px) {
  .section-content {
    width: 40vw;
    padding: 0 7em; /* Add padding on both sides */
  }

  .page-section {
    justify-content: flex-end;
  }
}

@media (min-width: 1200px) {
  .section-content {
    width: 40vw;
    padding: 0 8em; /* Add padding on both sides */
  }
  .page-section {
    justify-content: flex-end;
  }
  .title-wrapper {
    font-size: 8em;
  }
}

@media (min-width: 1400px) {
  .section-content {
    width: 36vw;
    padding: 0 8em; /* Add padding on both sides */
  }
}
