body,
#root,
html {
  background: #000000;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.App {
  z-index: 0;
  width: 100%;
  position: absolute;
}

.app-content {
  visibility: hidden;
  opacity: 0;
  transition: opacity 2s ease, visibility 0s linear 2s; /* Adjust timing as needed */
}

.app-content.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s ease; /* This ensures the fade-in effect */
}

.loader {
  border: 4px solid #424242; /* Light grey */
  border-top: 4px solid #ffffff; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 1);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.middle-layer {
  /* background-color: rgb(104, 84, 160); */
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  overflow: hidden;

  /* pointer-events: none; */
  /* border: 10px solid red; */
}

.foreground-layer {
  /* background-color: rgba(0, 255, 255, 0.087); */
  position: absolute;
  width: 100%;
  display: block; /* Ensure it behaves as a block-level element */
  z-index: 3;
  margin: 0;
}
.back-layer {
  background-color: rgb(0, 0, 0);
  /* position: absolute; */
  top: 0;
  left: 0;
  height: 100vh;
  color: white;
  z-index: 0;
  /* border: 10px solid red; */
}

.layerContainer {
  position: relative; /* Or 'fixed', depending on your layout needs */
  width: 100vw;
  /* height: 100vh; */
}

.r3f-canvas,
.canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  /* pointer-events: none; */
  /* opacity: 0.65; */
  /* display: none; */
}

.logo {
  position: fixed;
  top: 24;
  left: 19;
  z-index: 10000;

  transform: scale(0.5);
  transform-origin: top left;
}

@media (min-width: 300px) {
  .logo {
    visibility: hidden;
  }
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .logo {
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  .logo {
    visibility: visible;
    transform: scale(0.35);
  }
}

@media (min-width: 1200px) {
  .logo {
    transform: scale(0.35);
  }
}

@media (min-width: 1400px) {
  .logo {
    transform: scale(0.5);
  }
}
